<template>
  <div class="flex gap-2">
    <p-button
      v-show="!emailVisible"
      color="secondary"
      icon="envelope"
      @click="onShowSendEmail"
    >{{ $tk("Reports.SendCopyOfInvoice") }}</p-button>
    <form
      v-show="emailVisible"
      class="flex gap-2 p-1 -m-1 bg-green-100 rounded"
      @submit.prevent="onSubmitEmail"
    >
      <p-input
        v-model="invoiceEmail"
        :focus="emailVisible"
        class="w-48"
        iconInsideLeft="envelope"
        placeholder="E-post"
        type="email"
      />
      <p-button
        :disabled="invoiceEmail === ''"
        color="primary"
        icon="envelope"
        type="submit"
      >{{ $tk("Common.Actions.Send") }}</p-button>
      <p-button
        color="danger"
        icon=""
        @click="emailVisible = false"
      >{{ $tk("Common.Actions.Cancel") }}</p-button>
    </form>
  </div>
</template>

<script>
import http from "@/utilities/http"

export default {
  props: {
    invoiceId: {
      type: String,
    },
    invoiceIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      emailVisible: false,
      invoiceEmail: ""
    }
  },
  methods: {
    onShowSendEmail() {
      this.emailVisible = true;
    },
    async onSubmitEmail() {

      try {

        let promises = [];

        if (this.invoiceId) {
          promises.push(http.get("invoiceDocument", {
            params: {
              id: this.invoiceId,
              sendToEmail: this.invoiceEmail
            }
          }));
        }

        if (this.invoiceIds.length > 0) {
          for (let i = 0; i < this.invoiceIds.length; i++) {
            promises.push(http.get("invoiceDocument", {
              params: {
                id: this.invoiceIds[i],
                sendToEmail: this.invoiceEmail
              }
            }));
          }
        }

        await Promise.all(promises);

        this.emailVisible = false;
        this.invoiceEmail = "";
      } catch (error) {
        console.error("Error on submit email:", error);
      }
    }
  }
}

</script>